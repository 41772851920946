import styled from "styled-components"

export const BodyWrapper = styled.div`
  display: flex;
  font-style: normal;
  flex-wrap: wrap;
  padding-inline: 8px;
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: start;
  width: 100%;

  > * {
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const Column = styled.div`
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 0 0px;
  max-width: 100%;
  margin-left: 0%;
  right: auto;
  left: auto;

  div {
    display: flex;
    flex-direction: column;
  }
`

export const GroupTitle = styled.span`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1.5em;
  line-height: 34px;
  color: white;
  font-weight: 700;
  padding-bottom: 8px;
  margin-inline: auto;
  margin-top: 20px;
  margin-bottom: 9px;
`

export const TrackImageContainer = styled.div`
  width: 33%;
  margin-right: 45px;
  cursor: pointer;
  max-width: 330px;
  max-height: 200px;
  min-width: 327px;
  min-height: 200px;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.01);
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-right: 0px;
    max-width: 430px;
    min-width: 92vw;
  }
`

export const TrackList = styled.div`
  width: 100%;
  padding: 8px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

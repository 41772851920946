import React from "react"
import styled from "styled-components"

const InputText = styled.input`
  outline: 0;
  line-height: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  background: white;
  word-break: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  appearance: none;
  font-size: 1em;
  font-weight: 500;
  width: 100%;
  z-index: 2;
  margin: 0;
  min-height: 54px;
  border: 2px solid transparent;
  padding: 0 16px;
  font-family: Roboto;

  &:focus {
    box-shadow: 0 0 0 0 #fa4616;
    border: 2px solid #fa4616;
  }

  &::placeholder {
    font-weight: 500;
    color: #888888;
  }
`

export default function TextField(props) {
  const { type, placeHolder, inputStyle, value, onChange, onKeyDown } = props
  return (
    <InputText
      type={type}
      placeholder={placeHolder}
      style={inputStyle}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  )
}

import styled from "styled-components"
import Button from "../components/Button"

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: start;
  width: 100%;

  > * {
    margin-top: 15px;
  }
`
export const SearchRow = styled.div`
  margin-top: 0px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`
export const Column = styled.div`
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  ${"" /* padding-left: 15px;
  padding-right: 15px; */}
  flex: 1 0 0px;
  max-width: 100%;
  margin-left: 0%;
  right: auto;
  left: auto;

  div {
    display: flex;
    flex-direction: column;
  }
`

export const DownloadButtonContainer = styled.div`
  max-width: 373px;
  min-width: 250px;

  @media (max-width: 768px) {
    max-width: 319px;
    min-width: 310px;
  }
`

export const FilterIconImage = styled.img`
  max-height: 56px;
  max-width: 56px;
  margin-left: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`

export const ImageContainer = styled.div`
  align-content: end;
  justify-content: end;
  display: flex;
  max-width: 379px;
  min-width: 200;
  max-height: 171px;
  margin-top: 15px;
  margin-inline: 46px;

  @media (max-width: 768px) {
    display: none !important;
  }
`

export const LogoImage = styled.img`
  object-fit: contain;
  border-radius: 8px;
  max-width: 379px;
  max-height: 171px;
  min-width: 200px;
  width: 100%;
`
export const SearchButton = styled.div`
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

export const SearchFieldWrapper = styled.div`
  width: 90%;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const Title2 = styled.span`
  line-height: 36px;
  margin-bottom: 20px;
  font-size: 3em;
  font-weight: 700;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 2em;
  }
`

export const Title3 = {
  fontSize: "1.75em",
  fontWeight: 700,
  letterSpacing: 0,
  color: "white",
  textAlign: "left",
  marginTop: 20,
  lineHeight: "32px",
}

export const TitleTrack = styled.span`
  color: #fa4616;
  text-align: left;
  line-height: 32px;
  margin-right: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 4em;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 2.5em;
  }
`

export const BuyTickets = styled(Button)`
  max-width: 373px;
  width: 100%;
  font-size: 1.5em;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

export const TitleTrackWrapper = styled.div`
  width: 100%;
  padding-left: 0px;

  @media (max-width: 768px) {
    padding-left: 5px;
  }
`

import React, { useState } from "react"
import {
  Column,
  DownloadButtonContainer,
  ImageContainer,
  LogoImage,
  Row,
  SearchButton,
  SearchFieldWrapper,
  SearchRow,
  Title2,
  Title3,
  TitleTrack,
  TitleTrackWrapper,
} from "../styles/searchHeader.style"
import TicketHossLogo from "../images/ticketHossLogo.svg"
import PitpayLogo from "../images/Pit Pay Orange .svg"

// import FilterIcon from "../images/filter_icon.svg"
import Button from "./Button"
import TextField from "./TextField"
import useDeviceType from "../utils/useDeviceType"
import DownloadApp from "./DownloapAppModal"

export default function SearchHeader({
  text,
  setSearch,
  handleKeyPress,
  search,
  onSearch,
  searchText,
}) {
  const deviceType = useDeviceType()
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  const [openDownloadApp, setOpenDownloadApp] = useState(false)
  const openDownloadModal = () => {
    document.body.style.overflow = "hidden"
    setOpenDownloadApp(true)
  }

  const onClose = () => {
    document.body.style.overflow = "visible"
    setOpenDownloadApp(false)
  }

  const redirectToExternalLink = () => {
    const externalLink = process.env.GATSBY_EXTERNAL_URL

    if (deviceType === "android" || deviceType === "ios") {
      window.location.href = externalLink
    } else {
      openDownloadModal()
    }
  }

  return (
    <>
      <iframe
        style={{ display: "none" }}
        height="0"
        width="0"
        id="loader"
      ></iframe>
      <TitleTrackWrapper>
        <TitleTrack>{text}</TitleTrack>
      </TitleTrackWrapper>
      <SearchRow className="Row">
        <SearchFieldWrapper>
          <TextField
            type="text"
            placeHolder={searchText}
            onChange={event => {
              setSearch(event.target.value)
            }}
            onKeyDown={handleKeyPress}
            value={search}
          />
        </SearchFieldWrapper>

        <SearchButton>
          <Button
            text="Search"
            style={{
              maxWidth: 145,
              minWidth: 145,
              marginLeft: -150,
              marginTop: 19,
            }}
            onClick={onSearch}
          />
        </SearchButton>
        <div style={{ width: "10%", paddingLeft: 10 }}></div>
        {openDownloadApp && <DownloadApp onClose={onClose} />}
      </SearchRow>
    </>
  )
}

//<FilterIconImage src={FilterIcon} alt="Filter Icon" />
